import consumerApi from "api/services/consumer-api";
import { ComplianceRuleset } from "redux/reducers/complianceRules";
import logError from "utils/logError";

const fallbackResponse: ComplianceRuleset = {
  regionCode: "Default",
  rules: {},
  subregionCode: "Default",
};

const getRegionComplianceRules = async (
  stateCode?: string,
  countryCode?: string,
): Promise<ComplianceRuleset | void> => {
  try {
    const { data } = await consumerApi.get<ComplianceRuleset>(
      "/api/compliance/rulesets",
      {
        params: {
          regionCode: countryCode,
          subregionCode: stateCode,
        },
      },
    );

    return data;
  } catch (e) {
    if (e.statusCode !== 404) {
      logError(e.message, {
        functionName: "getDispensaryComplianceRuleset",
        service: "consumer",
        statusCode: e.statusCode,
      });
    }
    return fallbackResponse;
  }
};

export default getRegionComplianceRules;
