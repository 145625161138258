import consumerApi from "api/services/consumer-api";
import { ApiResponse } from "custom-types/Deals";
import logError from "utils/logError";

export const getDispensaryDeals = async (
  slug: string,
  take = 20,
  skip = 0,
): Promise<ApiResponse> => {
  try {
    const {
      data: {
        data,
        metadata: { totalCount, availableFilters },
      },
    } = await consumerApi.get(
      `api/dispensaries/v1/${slug}/deals?take=${take}&skip=${skip}`,
    );
    return {
      data: data,
      metadata: { availableFilters, totalCount },
    };
  } catch (e) {
    logError(e.message, {
      functionName: "getDispensaryDeals",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return {
      data: [],
      metadata: { availableFilters: [], totalCount: 0 },
    };
  }
};
