import consumerApi from "api/services/consumer-api";
import { DISPENSARY_PLACEHOLDER_PHOTO } from "constants/dispensary";
import { Coordinates } from "custom-types/Coordinates";
import { Dispensary, DispensaryPhoto } from "custom-types/Dispensary";
import formatImgixUrl from "utils/image/formatImgixUrl";
import logError from "utils/logError";

const mapDispensaryImages = (dispensary: Dispensary): Dispensary => {
  try {
    const newPhotos = dispensary.photos.map<DispensaryPhoto>(
      (photo: DispensaryPhoto) => {
        return {
          ...photo,
          imageUrl: formatImgixUrl(photo.imageUrl),
        };
      },
    );

    if (dispensary.coverPhotoUrl) {
      dispensary.coverPhotoUrl =
        formatImgixUrl(dispensary.coverPhotoUrl) ||
        DISPENSARY_PLACEHOLDER_PHOTO;

      newPhotos.unshift({
        id: -1,
        imageUrl: formatImgixUrl(dispensary.coverPhotoUrl),
      });
    }

    dispensary.logoUrl =
      formatImgixUrl(dispensary.logoUrl) || DISPENSARY_PLACEHOLDER_PHOTO;

    return {
      ...dispensary,
      logoUrl: formatImgixUrl(dispensary.logoUrl),
      photos: newPhotos,
    };
  } catch (e) {
    logError("Processing error in mapDispensaryImages in getDispensary");

    return dispensary;
  }
};

export const getDispensary = async (
  slug: string,
  coordinates?: Coordinates,
): Promise<Dispensary | null> => {
  try {
    const { data } = await consumerApi.get(`/api/dispensaries/v1/${slug}`, {
      params: coordinates || {},
    });

    return mapDispensaryImages(data);
  } catch (e) {
    // 404 is expected if the dispensary doesn't exist.
    if (e.statusCode !== 404) {
      logError(e.message, {
        functionName: "getDispensary",
        service: "consumer",
        statusCode: e.statusCode,
      });
    }

    return null;
  }
};
