import type { IncomingMessage, ServerResponse } from "http";
import { NextPageContext } from "next";
import URL from "url";

import publicConfig from "config/public";
import { Dispensary } from "custom-types/Dispensary";

import logError from "./logError";

type RetailType = "cbd-store" | "clinic" | "dispensary";

const retailTypes: { [T in RetailType]: string } = {
  "cbd-store": "/cbd-store",
  clinic: "/doctors",
  dispensary: "/dispensary-info",
};

const retailTypesCA: { [T in RetailType]: string } = {
  get "cbd-store"(): never {
    throw new Error("cbd-stores should not exist on leafly.ca");
  },
  clinic: "/doctors",
  dispensary: "/cannabis-store",
};

const { leaflyDomain, leaflyCaDomain } = publicConfig;

// Check for routing redirects, return dispensary, fallback to Finder
export const validateDispensary = async ({
  req,
  res,
  query,
  countryCode,
  dispensary,
}: {
  req: IncomingMessage;
  res: ServerResponse;
  query: NextPageContext["query"];
  countryCode: string;
  dispensary?: Dispensary | null;
}): Promise<{
  redirect: boolean;
}> => {
  const isCA = countryCode.toUpperCase() === "CA";
  const { slug: _, ...cleanedQuery } = query;

  try {
    if (!dispensary) {
      permanentRedirect(
        `${isCA ? leaflyCaDomain : leaflyDomain}/dispensaries`,
        { res },
      );
      return { redirect: true };
    }

    const validateDispensaryDomain = (): { redirect: boolean } => {
      const { url } = req;
      const { country } = dispensary;

      if (
        country.toUpperCase() !== countryCode.toUpperCase() &&
        ["US", "CA"].includes(country.toUpperCase())
      ) {
        const correctDomain =
          country.toUpperCase() === "US" ? leaflyDomain : leaflyCaDomain;
        permanentRedirect(`${correctDomain}${url}`, {
          res,
        });
        return { redirect: true };
      }

      return { redirect: false };
    };

    // Check for redirects between US or CA pages as needed for dispensary-info vs cannabis-store
    const redirectCanadianUrlsForLocalization = (): { redirect: boolean } => {
      const { slug, country } = dispensary;

      const shouldRedirectCanada =
        isCA && req.url?.startsWith("/dispensary-info");
      const shouldRedirectUS = !isCA && req.url?.startsWith("/cannabis-store");

      if (!shouldRedirectCanada && !shouldRedirectUS) {
        return { redirect: false };
      }

      let redirectUrl;
      if (shouldRedirectCanada) {
        if (country?.toLowerCase() === "ca") {
          redirectUrl = `/cannabis-store/${slug}`;
        } else {
          redirectUrl = "/dispensaries";
        }
      } else {
        redirectUrl = `/dispensary-info/${slug}`;
      }

      permanentRedirect(
        URL.format({
          pathname: redirectUrl,
          query: cleanedQuery,
        }),
        { res },
      );
      return { redirect: true };
    };

    // Check for redirects based on retail types, CBD stores etc.
    const redirectByRetailType = (): { redirect: boolean } => {
      const { slug, retailType } = dispensary;
      const { url } = req;
      const localRetailType = isCA ? retailTypesCA : retailTypes;
      const correctUrlPrefix = localRetailType[retailType as RetailType];

      if (!url?.startsWith(correctUrlPrefix)) {
        permanentRedirect(
          URL.format({
            pathname: `${correctUrlPrefix}/${slug}`,
            query: cleanedQuery,
          }),
          { res },
        );
        return { redirect: true };
      }

      return { redirect: false };
    };

    if (req && res) {
      const domainRedirectResult = validateDispensaryDomain();
      if (domainRedirectResult.redirect) {
        return { redirect: true };
      }

      const canadianRedirects = redirectCanadianUrlsForLocalization();
      if (canadianRedirects.redirect) {
        return { redirect: true };
      }

      const retailRedirects = redirectByRetailType();
      if (retailRedirects.redirect) {
        return { redirect: true };
      }
    }

    return { redirect: false };
  } catch (e) {
    logError(e.message || "Unknown error", {
      functionName: "validateDispensary",
    });

    redirect(`${isCA ? leaflyCaDomain : leaflyDomain}/dispensaries`, { res });
    return { redirect: true };
  }
};

const redirect = (url: string, { res }: { res: ServerResponse }) => {
  res.statusCode = 302;
  res.setHeader("Location", url);
  res.end();
};

const permanentRedirect = (url: string, { res }: { res: ServerResponse }) => {
  res.statusCode = 301;
  res.setHeader("Location", url);
  res.end();
};
